import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, vModelText as _vModelText, withDirectives as _withDirectives, withModifiers as _withModifiers, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col justify-center bg-white rounded-lg p-8 border-2 border-gray-300" }
const _hoisted_2 = { class: "space-y-8 divide-y divide-gray-200 sm:space-y-5" }
const _hoisted_3 = { class: "text-lg leading-6 font-medium text-gray-900" }
const _hoisted_4 = { class: "mt-1 max-w-2xl text-sm text-gray-500" }
const _hoisted_5 = { class: "space-y-6 sm:space-y-5" }
const _hoisted_6 = { class: "sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5" }
const _hoisted_7 = {
  for: "accelerometer",
  class: "block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
}
const _hoisted_8 = { class: "mt-1 sm:mt-0 sm:col-span-2" }
const _hoisted_9 = { class: "sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5" }
const _hoisted_10 = {
  for: "accelerometer",
  class: "block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
}
const _hoisted_11 = { class: "mt-1 sm:mt-0 sm:col-span-2" }
const _hoisted_12 = { class: "sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5" }
const _hoisted_13 = {
  for: "sc_puntuation",
  class: "block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
}
const _hoisted_14 = { class: "mt-1 sm:mt-0 sm:col-span-2" }
const _hoisted_15 = { class: "sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5" }
const _hoisted_16 = {
  for: "ae_puntuation",
  class: "block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
}
const _hoisted_17 = { class: "mt-1 sm:mt-0 sm:col-span-2" }
const _hoisted_18 = { class: "sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5" }
const _hoisted_19 = {
  for: "alfb_health_puntuation",
  class: "block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
}
const _hoisted_20 = { class: "mt-1 sm:mt-0 sm:col-span-2" }
const _hoisted_21 = { class: "sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5" }
const _hoisted_22 = {
  for: "alfb_adhd_puntuation_correct",
  class: "block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
}
const _hoisted_23 = { class: "mt-1 sm:mt-0 sm:col-span-2" }
const _hoisted_24 = { class: "sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5" }
const _hoisted_25 = {
  for: "alfb_adhd_puntuation_incorrect",
  class: "block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
}
const _hoisted_26 = { class: "mt-1 sm:mt-0 sm:col-span-2" }
const _hoisted_27 = { class: "pt-5" }
const _hoisted_28 = { class: "flex justify-end" }
const _hoisted_29 = {
  type: "submit",
  class: "ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_YesNoRadio = _resolveComponent("YesNoRadio")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("form", {
      class: "space-y-8 divide-y divide-gray-200",
      onSubmit: _cache[8] || (_cache[8] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.submit && _ctx.submit(...args)), ["prevent"]))
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", null, [
          _createElementVNode("div", null, [
            _createElementVNode("h3", _hoisted_3, _toDisplayString(_ctx.$t('TrackingForm.Title')), 1),
            _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t('TrackingForm.PersonalPatientInfo')), 1)
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("label", _hoisted_7, _toDisplayString(_ctx.$t('TrackingForm.accelerometer')), 1),
              _createElementVNode("div", _hoisted_8, [
                (_openBlock(), _createBlock(_component_YesNoRadio, {
                  value: _ctx.trackingInformation.accelerometer,
                  onYesnoevent: _cache[0] || (_cache[0] = ($event: any) => (_ctx.trackingInformation.accelerometer = $event)),
                  key: _ctx.trackingInformation.accelerometer
                }, null, 8, ["value"]))
              ])
            ]),
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("label", _hoisted_10, _toDisplayString(_ctx.$t('TrackingForm.accelerometer2')), 1),
              _createElementVNode("div", _hoisted_11, [
                (_openBlock(), _createBlock(_component_YesNoRadio, {
                  value: _ctx.trackingInformation.accelerometer2,
                  onYesnoevent: _cache[1] || (_cache[1] = ($event: any) => (_ctx.trackingInformation.accelerometer2 = $event)),
                  key: _ctx.trackingInformation.accelerometer2
                }, null, 8, ["value"]))
              ])
            ]),
            _createElementVNode("div", _hoisted_12, [
              _createElementVNode("label", _hoisted_13, _toDisplayString(_ctx.$t('TrackingForm.sc_puntuation')), 1),
              _createElementVNode("div", _hoisted_14, [
                _withDirectives(_createElementVNode("input", {
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.trackingInformation.sc_puntuation) = $event)),
                  type: "number",
                  step: "1",
                  name: "sc_puntuation",
                  id: "sc_puntuation",
                  class: "max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                }, null, 512), [
                  [_vModelText, _ctx.trackingInformation.sc_puntuation]
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_15, [
              _createElementVNode("label", _hoisted_16, _toDisplayString(_ctx.$t('TrackingForm.ae_puntuation')), 1),
              _createElementVNode("div", _hoisted_17, [
                _withDirectives(_createElementVNode("input", {
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.trackingInformation.ae_puntuation) = $event)),
                  type: "number",
                  step: "1",
                  name: "ae_puntuation",
                  id: "ae_puntuation",
                  class: "max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                }, null, 512), [
                  [_vModelText, _ctx.trackingInformation.ae_puntuation]
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_18, [
              _createElementVNode("label", _hoisted_19, _toDisplayString(_ctx.$t('TrackingForm.alfb_health_puntuation')), 1),
              _createElementVNode("div", _hoisted_20, [
                _withDirectives(_createElementVNode("input", {
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.trackingInformation.alfb_health_puntuation) = $event)),
                  type: "number",
                  step: "1",
                  name: "alfb_health_puntuation",
                  id: "alfb_health_puntuation",
                  class: "max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                }, null, 512), [
                  [_vModelText, _ctx.trackingInformation.alfb_health_puntuation]
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_21, [
              _createElementVNode("label", _hoisted_22, _toDisplayString(_ctx.$t('TrackingForm.alfb_adhd_puntuation_correct')), 1),
              _createElementVNode("div", _hoisted_23, [
                _withDirectives(_createElementVNode("input", {
                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.trackingInformation.alfb_adhd_puntuation_correct) = $event)),
                  type: "number",
                  step: "1",
                  name: "alfb_adhd_puntuation_correct",
                  id: "alfb_adhd_puntuation_correct",
                  class: "max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                }, null, 512), [
                  [_vModelText, _ctx.trackingInformation.alfb_adhd_puntuation_correct]
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_24, [
              _createElementVNode("label", _hoisted_25, _toDisplayString(_ctx.$t('TrackingForm.alfb_adhd_puntuation_incorrect')), 1),
              _createElementVNode("div", _hoisted_26, [
                _withDirectives(_createElementVNode("input", {
                  "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.trackingInformation.alfb_adhd_puntuation_incorrect) = $event)),
                  type: "number",
                  step: "1",
                  name: "alfb_adhd_puntuation_incorrect",
                  id: "alfb_adhd_puntuation_incorrect",
                  class: "max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                }, null, 512), [
                  [_vModelText, _ctx.trackingInformation.alfb_adhd_puntuation_incorrect]
                ])
              ])
            ])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_27, [
        _createElementVNode("div", _hoisted_28, [
          _createElementVNode("button", {
            onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.$router.back())),
            type: "button",
            class: "bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          }, _toDisplayString(_ctx.$t('TrackingForm.Back')), 1),
          _createElementVNode("button", _hoisted_29, _toDisplayString(_ctx.$t('TrackingForm.Save')), 1)
        ])
      ])
    ], 32)
  ]))
}