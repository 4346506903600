
import Patient from '@/models/Patient'
import Swal from 'sweetalert2'
import YesNoRadio from '@/components/YesNoRadio.vue'
import { Options, Vue } from 'vue-class-component'
import { mapActions, mapGetters } from 'vuex'

@Options({
  components: {
    YesNoRadio
  },
  methods: mapActions(['downloadTrackingInformation', 'updateTrackingInformation']),
  computed: mapGetters(['patient', 'trackingInformation'])
})
export default class TrackingForm extends Vue {
  patient!: Patient
  trackingInformation!: Patient['tracking']

  // Actions
  updateTrackingInformation!: (payload: {
    id: number
    trackingInfo: Patient['tracking']
  }) => Promise<void>

  downloadTrackingInformation!: () => Promise<void>

  async created (): Promise<void> {
    try {
      await this.downloadTrackingInformation()

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      const message = error.response.data.message
      if (message === 'Patient not found.') {
        await Swal.fire({
          icon: 'info',
          title: this.$t('TrackingForm.DownloadFailedTitle'),
          confirmButtonText: this.$t(
            'TrackingForm.DownloadFailedConfirmButton'
          )
        })

        this.$router.push({ name: 'Dashboard' })
      } else {
        this.$router.push({ name: 'Logout' })
      }
    }
  }

  async submit (): Promise<void> {
    try {
      await this.updateTrackingInformation({
        id: this.patient.id,
        trackingInfo: this.trackingInformation
      })

      this.$router.push({
        name: this.$route.meta.next,
        params: { id: this.patient.id }
      })
    } catch (error) {
      this.$router.push({ name: 'Logout' })
    }
  }
}
